import styled from 'styled-components';

// 카카오 맵
export const IceMapContainer = styled('div')`
  width: 100%;
  height: 150px;
`;

export const IceMap = styled('div')`
  width: 100%;
  height: 100%;
`;
